import { createUser, CreateUserDto, loginUser } from "./api";
import validator from "validator";

export const doLogin = (
  login: string,
  password: string,
  setError: (val: string) => void,
) => {
  setError("");

  if (!login) {
    setError("Įveskite prisijungimo vardą");
    return "";
  }
  if (!password) {
    setError("Įveskite slaptažodį");
    return "";
  }
  loginUser({
    password: password,
    username: login,
  }).then((res: any) => {
    if (res.data.token) {
      window.localStorage.setItem("token", res.data.token);
      window.location.href = "/";
    } else {
      setError("Neteisingas vartotojo vardas arba slaptažodis");
      return "";
    }
  });
};

type DoSignUpProps = {
  setError: (val: string) => void;
  passwordRepeat: string;
} & CreateUserDto;

export const doSignUp = async (props: DoSignUpProps) => {
  const {
    email,
    firstName,
    lastName,
    phone,
    password,
    username,
    setError,
    passwordRepeat,
  } = props;

  setError("");

  if (!username) {
    setError("Įveskite prisijungimo vardą");
    return false;
  }

  if (!email) {
    setError("Įveskite el. pašto adresą");
    return false;
  }

  if (!validator.isEmail(email)) {
    setError("Neteisingas el. pašto adresas");
    return false;
  }

  if (!password) {
    setError("Įveskite slaptažodį");
    return false;
  }
  if (password.length < 8) {
    setError("Slaptažodį turi sudaryti bent 8 simboliai");
    return false;
  }
  if (password !== passwordRepeat) {
    setError("Slaptažodžiai nesutampa");
    return false;
  }

  try {
    const res = await createUser({
      email,
      firstName,
      lastName,
      phone,
      password,
      username,
    });

    if (res.data.id) {
    } else {
      if (res.data.error) {
        setError(res.data.error);
        return false;
      } else {
        setError("Klaida");
        return false;
      }
    }
  } catch (e) {
    setError("Klaida");
    return false;
  }
  return true;
};
